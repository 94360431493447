import React from 'react';
import ReactDOM from 'react-dom';
import { addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import nl from 'react-intl/locale-data/nl';
import de from 'react-intl/locale-data/de';
import ru from 'react-intl/locale-data/ru';
import './index.css';
import Main from './Main';

addLocaleData(en);
addLocaleData(ru);
addLocaleData(nl);
addLocaleData(de);

ReactDOM.render(<Main />, document.getElementById('root'));
